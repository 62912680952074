import { type ReactElement } from "react";
import * as Popover from "@radix-ui/react-popover";
import { useAtomValue } from "jotai";

import { useAnonymousLogin } from "@sunrise/auth";
import { selectCanLogout } from "@sunrise/jwt";
import { useTranslator } from "@sunrise/translator";
import { Button, Link, Title } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";

import { LanguageSwitcher } from "../language-switcher";
import styles from "./settings.module.css";

function Settings(): ReactElement {
  const t = useTranslator();
  const canUserLogout = useAtomValue(selectCanLogout);
  const { login: loginAnonymousUser } = useAnonymousLogin({
    autoLogin: false,
  });

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button icon="settings" variant="text" hideLabel>
          {t("menu_settings")}
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="end" className={styles.content} sideOffset={8}>
          <div className={styles.header}>
            <Title level="h2" size="small" variant="title">
              {t("menu_settings")}
            </Title>
            <LanguageSwitcher />
          </div>
          {import.meta.env.MODE !== "production" && (
            <Link href={route.devTools.featureFlags()} variant="text">
              Feature Flags
            </Link>
          )}
          {canUserLogout && (
            <div className={styles.logoutContainer}>
              <Button variant="outlined" onClick={loginAnonymousUser}>
                {t("button_logout")}
              </Button>
            </div>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
export { Settings };
