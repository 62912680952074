import type { PropsWithChildren } from "react";
import { forwardRef } from "react";
import clsx from "clsx";

import type { Icons } from "@sunrise/icons";
import { Icon } from "@sunrise/icons";

import { Text } from "../text";
import styles from "./button.module.css";
import { ButtonOrLink, type ButtonProps } from "./button-or-link";

type SecondaryButtonProps = PropsWithChildren<{
  secondaryIcon?: Icons;
}> &
  ButtonProps;

/**
 * If a secondaryIcon is set, the primary icon will always be on the left and the secondary icon on the right.
 * The secondary icon gets hidden with `hideLabel`
 */
const SecondaryButton = forwardRef<HTMLButtonElement, SecondaryButtonProps>(
  function Button(props, ref) {
    const { secondaryIcon, children, ...buttonOrLinkProps } = props;
    const buttonProps = {
      ...buttonOrLinkProps,
      iconPosition: secondaryIcon ? "start" : props.iconPosition,
      className: clsx([
        props.variant !== "none" && styles.secondaryButton,
        props.className,
      ]),
    };
    return (
      <ButtonOrLink ref={ref} as="button" {...buttonProps}>
        {typeof children === "string" ? (
          <Text
            className={secondaryIcon && styles.content}
            size="large"
            variant="label"
          >
            {children}
          </Text>
        ) : (
          <div className={secondaryIcon && styles.content}>{children}</div>
        )}
        {secondaryIcon && <Icon className={styles.icon} name={secondaryIcon} />}
      </ButtonOrLink>
    );
  },
);

export { SecondaryButton };
