import type {
  AssetId,
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";

// TODO: extract, test and make generic.
export const routeParam = {
  // epg details
  details: {
    epgId: "" as EPGEntryId,
    assetId: "" as AssetId,
  } as const,
  // recording episode coming from a series details
  recordingEpisodeDetails: {
    recordingGroupId: "" as RecordingGroupId,
    recordingId: "" as RecordingId,
    assetId: "" as AssetId,
  },
  // single recording details
  singleRecordingDetails: {
    recordingId: "" as RecordingId,
    assetId: "" as AssetId,
  },
  // series details
  recordingGroupDetails: {
    recordingGroupId: "" as RecordingGroupId,
    assetId: "" as AssetId,
  },
} as const;

function makeRawParam<T extends Record<string, string>>(params: T): string {
  const param = Object.keys(params);
  // TODO: when a param is nullable, add it as a query param.
  return param.map((v) => `:${v}`).join("/");
}

function replaceInUrl<T extends Record<string, string>>(
  url: string,
  params: T,
): string {
  return Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replace(`:${key}`, value);
  }, url);
}

export const rawRoute = {
  details: `/details/${makeRawParam(routeParam.details)}`,
  home: "/home",
  search: "/search",
  tv: "/",
  guide: "/guide",
  recordings: "/recordings",
  recordingEpisodeDetails: `/recordings/episode/${makeRawParam(
    routeParam.recordingEpisodeDetails,
  )}`,
  recordingSeriesDetails: `/recordings/series/${makeRawParam(
    routeParam.recordingGroupDetails,
  )}`,
  recordingSingleDetails: `/recordings/single/${makeRawParam(
    routeParam.singleRecordingDetails,
  )}`,
  settings: "/settings",
  settingsProfile: "/settings/profile",
  settingsDeveloper: "/settings/developer",
  login: "/login",
  devTools: "/login/dev",
  devToolsFeatureFlags: "/login/dev/feature-flags",
  devToolsFeatureFlag: "/login/dev/feature-flags/:id",
  authenticate: "/authenticate",
} satisfies Record<string, string | Record<string, string>>;

export const route = {
  details: {
    root: (params: typeof routeParam.details) => {
      return replaceInUrl<typeof routeParam.details>(rawRoute.details, params);
    },
  },
  home: {
    root: () => rawRoute.home,
  },
  search: {
    root: () => rawRoute.search,
  },
  tv: {
    root: () => rawRoute.tv,
  },
  guide: {
    root: () => rawRoute.guide,
  },
  recordings: {
    root: () => rawRoute.recordings,
    single: (params: typeof routeParam.singleRecordingDetails) => {
      return replaceInUrl<typeof routeParam.singleRecordingDetails>(
        rawRoute.recordingSingleDetails,
        params,
      );
    },
    episode: (params: typeof routeParam.recordingEpisodeDetails) => {
      return replaceInUrl<typeof routeParam.recordingEpisodeDetails>(
        rawRoute.recordingEpisodeDetails,
        params,
      );
    },
    series: (params: typeof routeParam.recordingGroupDetails) => {
      return replaceInUrl<typeof routeParam.recordingGroupDetails>(
        rawRoute.recordingSeriesDetails,
        params,
      );
    },
  },
  settings: {
    root: () => rawRoute.settings,
    profile: () => rawRoute.settingsProfile,
  },
  login: {
    root: () => rawRoute.login,
  },
  authenticate: {
    root: () => rawRoute.authenticate,
  },
  devTools: {
    root: () => rawRoute.devTools,
    featureFlags: () => rawRoute.devToolsFeatureFlags,
  },
} satisfies Record<
  string,
  // the type accepts function with any number of arguments
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, (...params: any[]) => string>
>;
