import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import styles from "./dialog.module.css";

type BasicDialogProps = {
  onClose?: () => void;
  closable: boolean;
  open: boolean;
  children: ReactNode;
};

const BasicDialog = ({
  onClose,
  open,
  children,
  closable,
}: BasicDialogProps) => {
  const [isOpen, setOpen] = useState(open);

  const onInteract = useCallback(
    (event: Event) => {
      if (!closable) {
        event.preventDefault();
      }
      onClose?.();
    },
    [closable, onClose],
  );

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(open);
    }
  }, [isOpen, open]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={styles.content}
          onEscapeKeyDown={onInteract}
          onInteractOutside={onInteract}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export { BasicDialog as Dialog };
