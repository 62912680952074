import { type ReactElement, useCallback, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import {
  actionPlayerTogglePictureInPicture,
  playerAtom,
  playerCurrentEpgItemAtom,
  selectPlayerCurrentPlayRequest,
} from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";
import { usePlayFromBeginning } from "@sunrise/yallo-player-controls";
import { Button, SlideOut } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";

import { PlayerAudioAndSubtitles } from "./player-more-options-audio-and-subtitles";

const PlayerMoreOptions = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const playFromBeginning = usePlayFromBeginning();
  const currentChannelId = useAtomValue(
    selectPlayerCurrentPlayRequest,
  )?.channelId;
  const live = usePlayChannel({ channelId: currentChannelId });
  const epgEntry = useAtomValue(playerCurrentEpgItemAtom);
  const dispatchPlayer = useSetAtom(playerAtom);
  const togglePictureInPicture = useCallback(() => {
    dispatchPlayer(actionPlayerTogglePictureInPicture());
  }, [dispatchPlayer]);
  const t = useTranslator();
  return (
    <SlideOut.Root
      open={open}
      title={t("player_more_options_title")}
      trigger={
        <Button icon="more" variant="text" hideLabel>
          {t("more_player_actions_button")}
        </Button>
      }
      onOpenChange={setOpen}
    >
      {epgEntry && (
        <SlideOut.Link
          href={route.details.root({
            assetId: epgEntry?.asset.id,
            epgId: epgEntry?.id,
          })}
          icon="info"
          showMore
          onClick={() => setOpen(false)}
        >
          {t("player_more_options_more_info")}
        </SlideOut.Link>
      )}
      <SlideOut.Root
        title={t("player_more_options_audio_and_subtitles")}
        trigger={
          <SlideOut.Button icon="subtitlesAndAudio" showMore>
            {t("player_more_options_audio_and_subtitles")}
          </SlideOut.Button>
        }
      >
        <PlayerAudioAndSubtitles />
      </SlideOut.Root>
      <SlideOut.Button icon="record">
        {t("player_more_options_record")}
      </SlideOut.Button>
      <SlideOut.Button icon="pip" onClick={togglePictureInPicture}>
        {t("player_more_options_pip_picture_in_picture")}
      </SlideOut.Button>
      <SlideOut.Button icon="live" onClick={() => live.play()}>
        {t("player_more_options_watch_channel_live")}
      </SlideOut.Button>
      <SlideOut.Button icon="playFromBeginning" onClick={playFromBeginning}>
        {t("player_more_options_play_from_the_beginning")}
      </SlideOut.Button>
    </SlideOut.Root>
  );
};

export { PlayerMoreOptions };
