import { type TranslateFn } from "@sunrise/i18n";
import { getDayOfWeekTranslation } from "@sunrise/time";

import { programIsPlayingAtTime } from "./program-is-playing-at-time";

export function makeRelativeDayOfWeekTranslation(
  start: Date,
  end: Date,
  now: Date,
  t: TranslateFn,
): string {
  if (programIsPlayingAtTime({ startTime: start, endTime: end }, now)) {
    return t("live");
  }

  return getDayOfWeekTranslation(start, now, t);
}
