import { type ReactElement, Suspense, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import { useRoute } from "wouter";

import {
  actionPlayerToggleFullscreen,
  playerAtom,
  selectPlayerShouldBeFullscreen,
} from "@sunrise/player";
import { selectPlayerStatsEnabled } from "@sunrise/player-stats";
import { useTranslator } from "@sunrise/translator";
import { useIdle } from "@sunrise/utils";
import {
  PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS,
  useSeekbar,
} from "@sunrise/yallo-player-controls";
import { Link, VisuallyHidden } from "@sunrise/yallo-web-components";

import { rawRoute, route } from "../../config/route";
import { PlayerControls } from "./overlay/player-controls";
import { PlayerInfo } from "./overlay/player-info";
import { PlayerStats } from "./overlay/player-stats";
import styles from "./player-ui.module.css";

function PlayerUI(): ReactElement | null {
  const t = useTranslator();
  const idle = useIdle(PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS);
  const playerStatsEnabled = useAtomValue(selectPlayerStatsEnabled);
  const portalContainer = document.getElementById("player-controls-portal");
  const dispatchPlayer = useSetAtom(playerAtom);
  const [isTvPage] = useRoute(rawRoute.tv);
  const { isSeeking } = useSeekbar();
  const canHidePlayerControls = idle && !isSeeking;
  const isFullscreen = useAtomValue(selectPlayerShouldBeFullscreen);
  const toggleFullscreen = useCallback(() => {
    dispatchPlayer(actionPlayerToggleFullscreen());
  }, [dispatchPlayer]);

  if (!portalContainer) {
    throw new Error("#player-controls-portal is not defined");
  }

  useEffect(() => {
    document.documentElement.classList.toggle(
      "player-fullscreen-idle",
      canHidePlayerControls,
    );
    return () =>
      document.documentElement.classList.remove("player-fullscreen-idle");
  }, [canHidePlayerControls]);

  useEffect(() => {
    if (!isTvPage && isFullscreen) toggleFullscreen();
  }, [isTvPage, isFullscreen, toggleFullscreen]);

  return (
    <Suspense>
      {createPortal(
        isTvPage ? (
          <>
            <div className={styles.wrapper}>
              <div
                className={clsx(styles.overlay, {
                  [styles.visible ?? ""]: !canHidePlayerControls,
                })}
              >
                <PlayerInfo className={styles.playerInfo} />
                <PlayerControls className={styles.controls} />
              </div>
            </div>
            <PlayerInfo className={styles.mobilePlayerInfo} />
          </>
        ) : (
          <div className={styles.wrapper}>
            <Link
              className={styles.returnToPlayer}
              href={route.tv.root()}
              variant="none"
            >
              <VisuallyHidden>
                {t("player_mini_return_to_full_player")}
              </VisuallyHidden>
            </Link>
          </div>
        ),
        portalContainer,
      )}
      {playerStatsEnabled && <PlayerStats />}
    </Suspense>
  );
}

export { PlayerUI };
