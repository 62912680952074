import type { ReactNode } from "react";
import { useCallback } from "react";
import { clsx } from "clsx";
import { useSetAtom } from "jotai/index";

import type { ListDialog as ListDialogType } from "@sunrise/dialogs";
import { actionListDialogSetActiveId, dialogAtom } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";
import { Button, SlideOut } from "@sunrise/yallo-web-components";

import type { DialogProps } from "../dialog.types";
import styles from "./list-dialog.module.css";

export function ListDialog({
  dialog,
  "data-testid": testId = "list-dialog",
  doClose,
}: DialogProps & { dialog: ListDialogType }): ReactNode {
  const dispatchDialog = useSetAtom(dialogAtom);
  const t = useTranslatable();

  const hasConfirmationButton = !!(
    dialog.actionButtonLabel && dialog.onActionButtonClick
  );

  const onChange = useCallback(
    (value: Nullable<string>, sectionIdx: number) => {
      dialog.onButtonClick(value, sectionIdx);

      dispatchDialog(
        actionListDialogSetActiveId({
          value: value,
          sectionIdx,
        }),
      );
    },
    [dialog, dispatchDialog],
  );

  return (
    <>
      <div className={styles.content}>
        {dialog.sections.map((section, sectionIdx) => {
          return (
            <div
              key={`section-${sectionIdx}`}
              data-testid={`${testId}.section.${sectionIdx}`}
            >
              {section.title && (
                <SlideOut.Title
                  data-testid={`${testId}.section-title`}
                  level="h3"
                  size="small"
                >
                  {t(section.title)}
                </SlideOut.Title>
              )}
              {section.options.map((button, buttonIdx) => {
                const checked =
                  dialog.activeOptions[sectionIdx] === button.value;

                return dialog.radioButtons ? (
                  <SlideOut.Radio
                    key={`${button.value}-${buttonIdx}`}
                    checked={checked}
                    data-testid={`${testId}.button.${sectionIdx}.${buttonIdx}`}
                    labelPosition="end"
                    name="audio"
                    onChange={() => onChange(button.value, sectionIdx)}
                  >
                    {t(button.label) ?? button.value ?? `button ${buttonIdx}`}
                  </SlideOut.Radio>
                ) : (
                  <SlideOut.Button
                    className={clsx([checked && styles.checked])}
                    onClick={() => onChange(button.value, sectionIdx)}
                  >
                    {t(button.label) ?? button.value ?? `button ${buttonIdx}`}
                  </SlideOut.Button>
                );
              })}
            </div>
          );
        })}
      </div>
      {hasConfirmationButton && (
        <div className={styles.actions} data-testid={`${testId}.actions`}>
          <Button
            data-testid={`${testId}.actionButton`}
            variant="filled"
            onClick={() => {
              dialog.onActionButtonClick?.();
              doClose?.();
            }}
          >
            {t(dialog.actionButtonLabel)}
          </Button>
        </div>
      )}
    </>
  );
}
