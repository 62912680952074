import type { PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";

import styles from "./base-text.module.css";
import type { HeadingElements, TextElements, TextSize } from "./types";

type Props = {
  as: HeadingElements | TextElements;
  size?: TextSize;
  underline?: boolean;
  variant?: "body" | "label" | "title" | "headline" | "display";
  "aria-label"?: string;
  "aria-labelledby"?: string;
  title?: string;
  id?: string;
} & PropsWithChildren &
  CommonProps;

function BaseText({
  className,
  as = "span",
  size = "medium",
  variant = "body",
  children,
  underline,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  title,
  id,
}: Props): ReactElement {
  const Element = as;
  return (
    <Element
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      className={clsx(
        styles[size],
        styles[variant],
        underline && styles.underline,
        className,
      )}
      id={id}
      title={title}
    >
      {children}
    </Element>
  );
}

export { BaseText };
