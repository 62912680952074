import type { ComponentProps, ReactNode } from "react";
import { useId, useRef, useState } from "react";
import type { FixedSizeList } from "react-window";
import { isEqual } from "lodash-es";

import type { RecommendationListId } from "@sunrise/backend-types-core";
import { Button, Title } from "@sunrise/yallo-web-components";

import type { ContinueWatching } from "./continue-watching";
import styles from "./home-row-wrapper.module.css";
import type { Recommended } from "./recommended";
import type { ScrollableRow } from "./scrollable-row";

type HomeRowWrapperProps = {
  recommendationListId: RecommendationListId;
  title: string;
  renderChildren: typeof ContinueWatching | typeof Recommended;
} & Pick<ComponentProps<typeof ScrollableRow>, "numbered" | "displayType">;

export function HomeRowWrapper({
  recommendationListId,
  numbered,
  displayType,
  title,
  renderChildren,
}: HomeRowWrapperProps): ReactNode {
  const [needsArrows, setNeedsArrows] = useState({
    left: false,
    right: false,
  });

  const [arrowTargets, setArrowsTargets] = useState({
    left: 0,
    right: 0,
  });

  const listRef = useRef<FixedSizeList<string>>(null);
  const outerRef = useRef<HTMLDivElement>(null);

  const handleResize: ComponentProps<
    typeof renderChildren
  >["onResize"] = () => {
    if (typeof listRef !== "function" && listRef?.current && outerRef.current) {
      setNeedsArrows((prev) => ({
        ...prev,
        // when increasing the size, the left side will slowly scroll to zero, so we just need to care
        // about the right side here
        right:
          (outerRef.current?.clientWidth ?? 0) +
            (outerRef.current?.scrollLeft ?? 0) <
          // add a little bit of safe margin to avoid having this computed
          // only on the extreme edge of the row
          (outerRef.current?.scrollWidth ?? 0) - 100,
      }));
    }
  };

  const handleOnScroll: ComponentProps<typeof renderChildren>["onScroll"] = ({
    scrollOffset,
  }) => {
    if (!listRef.current || typeof listRef.current.props.width === "string") {
      return;
    }

    const newNeedsArrow = {
      left: scrollOffset > 0,
      right:
        (outerRef?.current?.scrollWidth ?? 0) -
          (scrollOffset + listRef.current.props.width) >
        0,
    };

    if (isEqual(newNeedsArrow, needsArrows)) {
      return;
    }

    setNeedsArrows(newNeedsArrow);
  };

  const handleOnItemsRendered: ComponentProps<
    typeof renderChildren
  >["onItemsRendered"] = (props) => {
    if (
      arrowTargets.left === props.visibleStartIndex &&
      arrowTargets.right === props.visibleStopIndex
    ) {
      return;
    }
    setArrowsTargets({
      right: props.visibleStopIndex ?? 0,
      left: props.visibleStartIndex ?? 0,
    });
  };

  const titleId = useId();

  const children = renderChildren({
    numbered,
    displayType,
    onScroll: handleOnScroll,
    onResize: handleResize,
    onItemsRendered: handleOnItemsRendered,
    listRef,
    outerRef,
    recommendationListId,
  });

  if (!children) {
    return;
  }

  return (
    <section aria-labelledby={titleId} className={styles.row}>
      <header className={styles.rowHeader}>
        <Title
          className={styles.title}
          id={titleId}
          level="h2"
          size="small"
          variant="headline"
        >
          {title}
        </Title>

        <div>
          <Button
            disabled={!needsArrows?.left}
            icon="arrowLeft"
            variant="text"
            hideLabel
            onClick={() =>
              listRef.current?.scrollToItem(arrowTargets.left, "end")
            }
          ></Button>

          <Button
            disabled={!needsArrows?.right}
            icon="arrowRight"
            variant="text"
            hideLabel
            onClick={() => {
              listRef.current?.scrollToItem(arrowTargets.right, "start");
            }}
          ></Button>
        </div>
      </header>
      {children}
    </section>
  );
}
