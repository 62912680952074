import type { ReactNode } from "react";

import type { ChannelRecommendation } from "@sunrise/yallo-recommendations";

import { Button } from "../button";
import { ChannelLogo } from "../logo";
import styles from "./channel-box.module.css";

type ChannelBoxProps = {
  onClick: () => void;
} & Omit<ChannelRecommendation, "channelId">;

export function ChannelBox({
  channelLogo,
  channelName,
  onClick,
}: ChannelBoxProps): ReactNode {
  return (
    <Button className={styles.button} variant="none" onClick={onClick}>
      <div className={styles.container}>
        <div
          className={styles.logoBackground}
          style={{
            backgroundImage: `url(${channelLogo})`,
          }}
        />
        <ChannelLogo
          className={styles.logo}
          logo={channelLogo}
          name={channelName}
        />
      </div>
    </Button>
  );
}
