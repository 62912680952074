import { isSameDay } from "date-fns";
import { useAtomValue } from "jotai/index";

import { datesRangeAtom } from "@sunrise/time";

import { currentlyVisibleDateAtom } from "../store/grid-state.selectors";

export const useGridDateNavigator = (): {
  canGoBack: boolean;
  canGoForward: boolean;
} => {
  const currentSelectedDate = useAtomValue(currentlyVisibleDateAtom);
  const datesRange = useAtomValue(datesRangeAtom);

  return {
    canGoBack: currentSelectedDate
      ? !isSameDay(datesRange[0], currentSelectedDate)
      : true,
    canGoForward: currentSelectedDate
      ? !isSameDay(datesRange[datesRange.length - 1], currentSelectedDate)
      : true,
  };
};
