import { type ReactElement, startTransition, useCallback } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { logButtonClickAtom } from "@sunrise/analytics";
import { type Language, languages } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

// import { useAnalytics } from "@/providers/analytics-provider";
import styles from "./language-switcher.module.css";

type Props = CommonProps;

function LanguageSwitcher({
  "data-testid": dataTestId = "language-switcher",
}: Props): ReactElement | null {
  const currentLanguage = useAtomValue(currentLanguageAtom);
  const t = useTranslator();

  const changeLocale = useAtomCallback(
    useCallback(async (get, set, nextLanguage: Language): Promise<void> => {
      const log = get(logButtonClickAtom);

      await log.invoke({
        type: "change_language",
        client_language: nextLanguage,
      });

      startTransition(() => {
        set(currentLanguageAtom, nextLanguage);
      });
    }, []),
  );

  return (
    <ul
      aria-label={t("languages")}
      className={styles.languageSwitcher}
      data-testid={dataTestId}
    >
      {languages.map((language) => (
        <li key={language}>
          <Button
            aria-label={t(`language_${language}`)}
            className={clsx(
              styles.language,
              currentLanguage === language && styles.active,
            )}
            data-testid={`${dataTestId}.${language}`}
            variant="none"
            onClick={() => changeLocale(language)}
          >
            {language}
          </Button>
        </li>
      ))}
    </ul>
  );
}

export { LanguageSwitcher };
