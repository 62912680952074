import type {
  ComponentProps,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import { useEffect, useState } from "react";
import { Drawer } from "vaul";

import { useTranslator } from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";

import { Button } from "../button";
import { Title } from "../text";
import styles from "./slide-out.module.css";

const query = "(width <= 800px)";

type Props = PropsWithChildren<{
  title: Nullable<string>;
  trigger?: ReactNode;
}> &
  CommonProps &
  Pick<
    ComponentProps<typeof Drawer.Root>,
    "open" | "onOpenChange" | "dismissible"
  >;

const SlideOut = ({
  children,
  trigger,
  title,
  open,
  onOpenChange,
  dismissible = true,
}: Props): ReactElement => {
  const t = useTranslator();
  const [shouldSlideFromBottom, setShouldSlideFromBottom] = useState(
    window.matchMedia(query).matches,
  );

  useEffect(() => {
    const mql = window.matchMedia(query);
    const handleMqlChange = (event: MediaQueryListEvent): void => {
      setShouldSlideFromBottom(event.matches);
    };
    mql.addEventListener("change", handleMqlChange);
    return () => mql.removeEventListener("change", handleMqlChange);
  });

  const props = shouldSlideFromBottom
    ? { snapPoints: [0.5, 1], fadeFromIndex: 0 }
    : {};

  return (
    <Drawer.Root
      key={shouldSlideFromBottom.toString()}
      direction={shouldSlideFromBottom ? "bottom" : "right"}
      dismissible={dismissible}
      open={open}
      onOpenChange={onOpenChange}
      {...props}
    >
      {trigger ? <Drawer.Trigger asChild>{trigger}</Drawer.Trigger> : null}
      <Drawer.Portal>
        <Drawer.Overlay className={styles.overlay} />
        <Drawer.Content className={styles.content}>
          <div className={styles.inner}>
            <div className={styles.header}>
              <Drawer.Title asChild>
                <Title level="h2" size="medium" variant="title">
                  {title}
                </Title>
              </Drawer.Title>
              {dismissible && (
                <Drawer.Close asChild>
                  <Button icon="close" variant="text" hideLabel>
                    {t("button_close")}
                  </Button>
                </Drawer.Close>
              )}
            </div>
            {children}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export { SlideOut };
